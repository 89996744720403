import { SCREEN_SIZES } from '~/constants/screen-sizes'

/**
 * This composable returns boolean values for each breakpoint.
 *
 * @example const { isSmallBreakpoint } = useBreakpoint()
 */
export default function useBreakpoint() {
  const { isMobile, isMobileOrTablet, isDesktopOrTablet, isDesktop } = useDevice()
  const { hydrationStatus } = useHydrationState()

  const breakpoints = useBreakpoints({
    small: SCREEN_SIZES.small,
    medium: SCREEN_SIZES.medium,
    large: SCREEN_SIZES.large,
    xLarge: SCREEN_SIZES.xLarge,
    xxLarge: SCREEN_SIZES.xxLarge,
  })

  return {
    isSmallBreakpoint: computed(() => (hydrationStatus.value ? breakpoints.greaterOrEqual('small').value : isMobile)),
    isMediumBreakpoint: computed(() =>
      hydrationStatus.value ? breakpoints.greaterOrEqual('medium').value : isMobileOrTablet,
    ),
    isLargeBreakpoint: computed(() =>
      hydrationStatus.value ? breakpoints.greaterOrEqual('large').value : isDesktopOrTablet,
    ),
    isXlargeBreakpoint: computed(() =>
      hydrationStatus.value ? breakpoints.greaterOrEqual('xLarge').value : isDesktop,
    ),
    isXXlargeBreakpoint: computed(() =>
      hydrationStatus.value ? breakpoints.greaterOrEqual('xxLarge').value : isDesktop,
    ),
  }
}
